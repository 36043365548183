// Ring Media Queries Breakpoints values - integrated with default Bootstrap values

@mixin mobile-xs-from {
    @media all and (min-width: $xs-size-from) { @content; }
}

@mixin mobile-sm-from {
    @media all and (min-width: $sm-size-from) { @content; }
}

@mixin tablet-md-from {
    @media all and (min-width: $md-size-from) { @content; }
}

@mixin tablet-lg-from {
    @media all and (min-width: $lg-size-from) { @content; }
}

@mixin desktop-xl-from {
    @media all and (min-width: $xl-size-from) { @content; }
}

@mixin mobile-xs {
    @media all and (min-width: $xs-size-from) and (max-width: $xs-size-to) { @content; }
}

@mixin mobile-sm {
    @media all and (min-width: $sm-size-from) and (max-width: $sm-size-to) { @content; }
}

@mixin mobile-all {
    @media all and (min-width: $xs-size-from) and (max-width: $sm-size-to) { @content; }
}

@mixin tablet-md {
    @media all and (min-width: $md-size-from) and (max-width: $md-size-to) { @content; }
}

@mixin tablet-lg {
    @media all and (min-width: $lg-size-from) and (max-width: $lg-size-to) { @content; }
}

@mixin tablet-all {
    @media all and (min-width: $md-size-from) and (max-width: $lg-size-to) { @content; }
}

@mixin mobile-xs-to {
    @media all and (max-width: $xs-size-to) { @content; }
}

@mixin mobile-sm-to {
    @media all and (max-width: $sm-size-to) { @content; }
}

@mixin tablet-md-to {
    @media all and (max-width: $md-size-to) { @content; }
}

@mixin tablet-lg-to {
    @media all and (max-width: $lg-size-to) { @content; }
}
