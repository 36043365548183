@mixin standard-animation-mix($property: all, $animation_time: $standard_animation_time, $type_of_effect: ease-in-out) {
    -webkit-transition: $property $animation_time $type_of_effect;
    -moz-transition: $property $animation_time $type_of_effect;
    -o-transition: $property $animation_time $type_of_effect;
    transition: $property $animation_time $type_of_effect;
    // Optimization for opacity changing
    @if $property == opacity {
        will-change: opacity;
    }
}
