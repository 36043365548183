@mixin vertical-horizontal-center-mix($top_val: 50%, $left_val: 50%) {
    // Only horizontal
    @if $top_val == 0 and $left_val != 0 {
        left: $left_val;
        -moz-transform: translate(-$left_val);
        -ms-transform: translate(-$left_val);
        -webkit-transform: translate(-$left_val);
        transform: translate(-$left_val);
    }
    // Only vertical
    @else if $top_val != 0 and $left_val == 0 {
        top: $top_val;
        -moz-transform: translate(0, -$top_val);
        -ms-transform: translate(0, -$top_val);
        -webkit-transform: translate(0, -$top_val);
        transform: translate(0, -$top_val);
    }
    // Vertical + horizontal
    @else if $top_val > 0 and $left_val > 0 {
        top: $top_val;
        left: $left_val;
        -moz-transform: translate(-$left_val, -$top_val);
        -ms-transform: translate(-$left_val, -$top_val);
        -webkit-transform: translate(-$left_val, -$top_val);
        transform: translate(-$left_val, -$top_val);
    }
    // Default: vertical + horizontal
    @else {
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

// With extra effect
@mixin vertical-horizontal-center-mix-effect($top_val: 50%, $left_val: 50%, $extra:null) {
    // Only horizontal
    @if $top_val == 0 and $left_val != 0 {
        left: $left_val;
        -moz-transform: translate(-$left_val) $extra;
        -ms-transform: translate(-$left_val) $extra;
        -webkit-transform: translate(-$left_val) $extra;
        transform: translate(-$left_val) $extra;
    }
    // Only vertical
    @else if $top_val != 0 and $left_val == 0 {
        top: $top_val;
        -moz-transform: translate(0, -$top_val) $extra;
        -ms-transform: translate(0, -$top_val) $extra;
        -webkit-transform: translate(0, -$top_val) $extra;
        transform: translate(0, -$top_val) $extra;
    }
    // Vertical + horizontal
    @else if $top_val > 0 and $left_val > 0 {
        top: $top_val;
        left: $left_val;
        -moz-transform: translate(-$left_val, -$top_val) $extra;
        -ms-transform: translate(-$left_val, -$top_val) $extra;
        -webkit-transform: translate(-$left_val, -$top_val) $extra;
        transform: translate(-$left_val, -$top_val) $extra;
    }
    // Default: vertical + horizontal
    @else {
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%) $extra;
        -ms-transform: translate(-50%, -50%) $extra;
        -webkit-transform: translate(-50%, -50%) $extra;
        transform: translate(-50%, -50%) $extra;
    }
}
