@import "project/@variables";

$xs-size-from: 0 !default;
$xs-size-to  : 575px !default;
$sm-size-from: 576px !default;
$sm-size-to  : 767px !default;
$md-size-from: 768px !default;
$md-size-to  : 991px !default;
$lg-size-from: 992px !default;
$lg-size-to  : 1299px !default;
$xl-size-from: 1300px !default;
$padding-mobile: 15px !default;
$padding-tablet: 15px !default;
$ring-gutter-width: 30px !default;

$standard_animation_short_time: 0.2s !default;
$standard_animation_time: 0.3s !default;
$standard_animation_long_time: 0.6s !default;

$grid-breakpoints: (
    xs: $xs-size-from,
    sm: $sm-size-from,
    md: $md-size-from,
    lg: $lg-size-from,
    xl: $xl-size-from
) !default;

$container-max-widths: (
    sm: $sm-size-from,
    md: $md-size-from,
    lg: $lg-size-from,
    xl: $xl-size-from
) !default;

$standard-css-vars: (
    "ring-color-background": (
        "light": "#ffffff",
        "dark": "#000000"
    ),
    "ring-color-text": (
        "light": "#000000",
        "dark": "#ffffff"
    ),
    "spotLiteColor": (
        "light": "#000",
        "dark": "#000"
    ),
    "termsLinkColor": (
        "light": "#000",
        "dark": "#000"
    ),
    "pushSubmitButtonTxtColor": (
        "light": "#fff",
        "dark": "#fff"
    ),
) !default;

$multimediaReport-font-family: lato,helvetica,arial,sans-serif !default;
$ring-color-black: #000 !default;
$ring-color-white: #fff !default;
$ring-color-grey: #e3e3e3 !default;
$ring-color-yellow: #ffd21e !default;
$ring-color-green: #00a03c !default;
$ring-color-darkblue: #1450b4 !default;
$ring-color-lightblue: #00b4f0 !default;
$ring-color-pink: #fa28aa !default;
$ring-color-red: #eb1e32 !default;
$ring-standard-shadow: 0 0 8px rgba(255, 255, 255, 0.1) !default;
$ring-font-size: 16px !default;
$ring-font-family: "NeueHaasGroteskDisp Pro" !default;
$ring-headers-font-family: "NeueHaasGroteskDisp Pro" !default;

$demo-header-text-rgb: rgb(222, 222, 222);
$demo-css-vars: (
    "ring-color-background": (
        "light": "#fbfbfb",
        "dark": "#222"
    ),
    "ring-color-background-rgb": (
        "light": "222, 222, 222",
        "dark": "34, 34, 34"
    ),
    "ring-color-text": (
        "light": "#333",
        "dark": "#dedede"
    ),
    "ring-color-text-rgb": (
        "light": "51, 51, 51",
        "dark": "222, 222, 222"
    ),
    "spotLiteColor": (
        "light": "#000",
        "dark": "#000"
    ),
    "termsLinkColor": (
        "light": "#000",
        "dark": "#000"
    ),
    "pushSubmitButtonTxtColor": (
        "light": "#fff",
        "dark": "#fff"
    ),
);
