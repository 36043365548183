@function non-destructive-map-merge($parent-map, $child-map) {
    $result: $parent-map;

    @each $key, $value in $child-map {
        @if (not map-has-key($result, $key)) or (type-of(map-get($result, $key)) != type-of($value)) or (not (type-of(map-get($result, $key)) == map and type-of($value) == map)) {
            $result: map-merge($result, ($key: $value));
        }
        @else {
            $result: map-merge($result, ($key: non-destructive-map-merge(map-get($result, $key), $value)));
        }
    }

    @return $result;
}

@mixin prepareThemeVariables($themeColor: "light", $customVars: null) {
    $mergedCssVars: if($customVars == null, $standard-css-vars, non-destructive-map-merge($standard-css-vars, $customVars));

    $result: ();

    @each $cssVar in map-keys($mergedCssVars) {
        $themes: map-get($mergedCssVars, $cssVar);
        $value: map-get($themes, $themeColor);

        @if ($value == null) {
            @each $test in map-keys($themes) {
                @if ($value == null) {
                    $value: map-get($themes, $test);
                }
            }
        }
        $result: map-merge($result, ("--#{$cssVar}": $value));
    }

    @each $varName, $value in $result {
        #{$varName}: #{$value};
    }
}
