@import "@variables";
@import "./common/mixins/makeRingContainer";
@import "./common/mixins/mediaQueries";
@import "./common/mixins/ringCutText";
@import "./common/mixins/ringHeaders";
@import "./common/mixins/ringWidgetInColumns";
@import "./common/mixins/standardAnimationMix";
@import "./common/mixins/vars";
@import "./common/mixins/verticalHorizontalCenterMix";
@import "project/@globals";
