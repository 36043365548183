/**
Creates "linear function" for property depending on VW between two points($start/$end).

USAGE
@include flProperty("margin-bottom", min_prop_value, max_prop_value, min_prop_value_at_start, max_prop_value_at_end);
@include flProperty("margin-bottom", 10px, 30px, 320px, 1024px);
@include flProperty("max-width", 750px, 1050px, 1400px);
 */
@mixin flProperty($property, $min, $max, $start: 320, $end: 1920, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
    $multiplier: ($max - $min) / ($end - $start) * 100;
    $adder: ($min * $end - $max * $start) / ($end - $start);
    $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
    @if $clip and $clipAtStart {
        @media (max-width: #{$start + 0px}) {
            #{$property}: $min + 0px;
        }
    }
    @if $clip and $clipAtEnd {
        @media (min-width: #{$end + 0px}) {
            #{$property}: $max + 0px;
        }
    }
    #{$property}: $formula;
}
