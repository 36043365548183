// Widget custom width column-padding fix
@mixin ringWidgetInColumns($paddingValue:0, $small:false, $big:false) {
    @if ($big) {
        // WIDTH-row: 12
        &.col-md-12 {
            padding: $paddingValue/2 0 $paddingValue/2 0;
        }
        // WIDTH-row: 11, 10, 9, 8, 7
        &.col-md-11, &.col-md-10, &.col-md-9, &.col-md-8, &.col-md-7 {
            @include mobile-sm-to {
                &.ring-widget-left, &.ring-widget-center, &.ring-widget-right {
                    padding: $paddingValue/2 0 $paddingValue/2 0;
                }
            }

            @include tablet-md-from {
                &.ring-widget-left {
                    padding: $paddingValue/2 $paddingValue $paddingValue/2 0;
                }

                &.ring-widget-center {
                    padding: $paddingValue/2 $paddingValue $paddingValue/2 $paddingValue;
                }

                &.ring-widget-right {
                    padding: $paddingValue/2 0 $paddingValue/2 $paddingValue;
                }
            }
        }
    }

    // WIDTH-row: 6, 5
    &.col-md-6, &.col-md-5 {
        @include mobile-sm-to {
            &.ring-widget:nth-child(2n+0), &.ring-widget:nth-child(2n+1) {
                padding: $paddingValue/2 0 $paddingValue/2 0;
            }
        }

        @include tablet-md-from {
            &.ring-widget:nth-child(2n+0) {
                padding: $paddingValue/2 $paddingValue/2 $paddingValue/2 0;
            }

            &.ring-widget:nth-child(2n+1) {
                padding: $paddingValue/2 0 $paddingValue/2 $paddingValue/2;
            }
        }
    }
    // WIDTH-row: 4
    &.col-md-4 {
        @include mobile-sm-to {
            &.ring-widget:nth-child(3n+0), &.ring-widget:nth-child(3n+1), &.ring-widget:nth-child(3n+2) {
                padding: $paddingValue/2 0 $paddingValue/2 0;
            }
        }

        @include tablet-md-from {
            &.ring-widget:nth-child(3n+0) {
                padding: $paddingValue/2 $paddingValue/3 $paddingValue/2 $paddingValue/3;
            }

            &.ring-widget:nth-child(3n+1) {
                padding: $paddingValue/2 0 $paddingValue/2 $paddingValue * (2/3);
            }

            &.ring-widget:nth-child(3n+2) {
                padding: $paddingValue/2 $paddingValue * (2/3) $paddingValue/2 0;
            }
        }
    }
    // WIDTH-row: 3
    &.col-md-3 {
        @include mobile-sm-to {
            &.ring-widget:nth-child(4n+0), &.ring-widget:nth-child(4n+1), &.ring-widget:nth-child(4n+2), &.ring-widget:nth-child(4n+3) {
                padding: $paddingValue/2 0 $paddingValue/2 0;
            }
        }

        @include tablet-md-from {
            &.ring-widget:nth-child(4n+0) {
                padding: $paddingValue/2 $paddingValue/4 $paddingValue/2 $paddingValue/2;
            }

            &.ring-widget:nth-child(4n+1) {
                padding: $paddingValue/2 0 $paddingValue/2 $paddingValue * (3/4);
            }

            &.ring-widget:nth-child(4n+2) {
                padding: $paddingValue/2 $paddingValue * (3/4) $paddingValue/2 0;
            }

            &.ring-widget:nth-child(4n+3) {
                padding: $paddingValue/2 $paddingValue/2 $paddingValue/2 $paddingValue/4;
            }
        }
    }

    @if ($small) {
        // WIDTH-row: 2
        &.col-md-2 {
            @include mobile-sm-to {
                &.ring-widget:nth-child(6n+0),&.ring-widget:nth-child(6n+1),&.ring-widget:nth-child(6n+2),&.ring-widget:nth-child(6n+3),&.ring-widget:nth-child(6n+4),&.ring-widget:nth-child(6n+5) {
                    padding: $paddingValue/2 0 $paddingValue/2 0;
                }
            }

            @include tablet-md-from {
                &.ring-widget:nth-child(6n+0) {
                    padding: $paddingValue/2 $paddingValue/6 $paddingValue/2 $paddingValue * (2/3);
                }

                &.ring-widget:nth-child(6n+1) {
                    padding: $paddingValue/2 0 $paddingValue/2 $paddingValue * (5/6);
                }

                &.ring-widget:nth-child(6n+2) {
                    padding: $paddingValue/2 $paddingValue * (5/6) $paddingValue/2 0;
                }

                &.ring-widget:nth-child(6n+3) {
                    padding: $paddingValue/2 $paddingValue * (2/3) $paddingValue/2 $paddingValue/6;
                }

                &.ring-widget:nth-child(6n+4) {
                    padding: $paddingValue/2 $paddingValue/2 $paddingValue/2 $paddingValue/3;
                }

                &.ring-widget:nth-child(6n+5) {
                    padding: $paddingValue/2 $paddingValue/3 $paddingValue/2 $paddingValue/2;
                }
            }
        }
        // WIDTH-row: 1
        &.col-md-1 {
            @include mobile-sm-to {
                &.ring-widget:nth-child(12n+0),
                &.ring-widget:nth-child(12n+1),
                &.ring-widget:nth-child(12n+2),
                &.ring-widget:nth-child(12n+3),
                &.ring-widget:nth-child(12n+4),
                &.ring-widget:nth-child(12n+5),
                &.ring-widget:nth-child(12n+6),
                &.ring-widget:nth-child(12n+7),
                &.ring-widget:nth-child(12n+8),
                &.ring-widget:nth-child(12n+9),
                &.ring-widget:nth-child(12n+10),
                &.ring-widget:nth-child(12n+11) {
                    padding: $paddingValue/2 0 $paddingValue/2 0;
                }
            }

            @include tablet-md-from {
                &.ring-widget:nth-child(12n+0) {
                    padding: $paddingValue/2 $paddingValue/12 $paddingValue/2 $paddingValue * (5/6);
                }

                &.ring-widget:nth-child(12n+1) {
                    padding: $paddingValue/2 0 $paddingValue/2 $paddingValue * (11/12);
                }

                &.ring-widget:nth-child(12n+2) {
                    padding: $paddingValue/2 $paddingValue * (11/12) $paddingValue/2 0;
                }

                &.ring-widget:nth-child(12n+3) {
                    padding: $paddingValue/2 $paddingValue * (5/6) $paddingValue/2 $paddingValue/12;
                }

                &.ring-widget:nth-child(12n+4) {
                    padding: $paddingValue/2 $paddingValue * (9/12) $paddingValue/2 $paddingValue/6;
                }

                &.ring-widget:nth-child(12n+5) {
                    padding: $paddingValue/2 $paddingValue * (2/3) $paddingValue/2 $paddingValue/4;
                }

                &.ring-widget:nth-child(12n+6) {
                    padding: $paddingValue/2 $paddingValue * (7/12) $paddingValue/2 $paddingValue/3;
                }

                &.ring-widget:nth-child(12n+7) {
                    padding: $paddingValue/2 $paddingValue/2 $paddingValue/2 $paddingValue * (5/12);
                }

                &.ring-widget:nth-child(12n+8) {
                    padding: $paddingValue/2 $paddingValue * (5/12) $paddingValue/2 $paddingValue/2;
                }

                &.ring-widget:nth-child(12n+9) {
                    padding: $paddingValue/2 $paddingValue/3 $paddingValue/2 $paddingValue * (7/12);
                }

                &.ring-widget:nth-child(12n+10) {
                    padding: $paddingValue/2 $paddingValue/4 $paddingValue/2 $paddingValue * (2/3);
                }

                &.ring-widget:nth-child(12n+11) {
                    padding: $paddingValue/2 $paddingValue/6 $paddingValue/2 $paddingValue * (9/12);
                }
            }
        }
    }
}
