// Override here standard scss variables:
// $ring-gutter-width: 15px;

// Declaration of dynamic css variables:
// $projectCssVars: (
//    "variable_name": (
//    "theme_variation_1": "value",
//    "theme_variation_2": "value"
// ));
$project-css-vars: ();

$caslon-pro-font: 'ACaslonPro';
$caslon-two-font: 'CaslonTwoTwentyFour';
$relative-book-font: 'Relative-Book';
$black: #000000;
$white: #ffffff;
$bolero-gray: #EFEFEF;
$bolero-yellow: #FFFFF2;
$bolero-purple: #9743F4;
$bolero-orange: #FF682A;
$bolero-red: #EE0016;
$padding-mobile: 15px;
$padding-tablet: 15px;
$footer-background: #F9F9F9;

$boleroColors: (
    "Purple": $bolero-purple,
    "Orange": $bolero-orange,
    "Red": $bolero-red,
);
