// Ring Bootstrap grid overlays

// Ring container support
@mixin makeRingContainer($maxWidth: $xl-size-from) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include mobile-all() {
        max-width: 100%;
        padding-left: $padding-mobile;
        padding-right: $padding-mobile;
    }

    @include tablet-all {
        max-width: 100%;
        padding-left: $padding-tablet;
        padding-right: $padding-tablet;
    }

    @include desktop-xl-from {
        max-width: $maxWidth;
        padding-left: ($ring-gutter-width/2);
        padding-right: ($ring-gutter-width/2);
    }
}
